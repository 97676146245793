<template>
  <div class="osk-layout-mini-header">
    <HeaderMini />

    <div class="osk-layout-mini-header__container">
      <slot />

      <Footer class="osk-layout-mini-header__footer" />
    </div>

    <ErrorDialog v-model="isErrorDialog" />

    <AuthPhoneVerifyDialog
      v-if="needPhoneVerify && isMounted"
      v-model="isPhoneVerifyDialog"
    />

    <ClientOnly>
      <notifications
        class="osk-notify-group osk-notify-group_center"
        group="mainNotifications"
        :duration="300000"
        classes="osk-notify"
        position="top center"
        width="auto"
        animation-name="slide-down-notify"
      />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
const { isErrorDialog } = useErrorDialog();
const { isMounted } = useUiBreakpoints();
const {
  needPhoneVerify,
  isPhoneVerifyDialog,
} = usePhoneVerification();

onMounted(() => {
  if (isPhoneVerifyDialog.value) {
    isPhoneVerifyDialog.value = false;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-layout-mini-header {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__container {
    height: 100%;
  }
}
</style>
