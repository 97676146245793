<template>
  <header class="osk-header-mini">
    <!-- <HeaderMiniTop /> -->

    <div class="osk-header-mini__content">
      <div class="osk-wrapper">
        <nuxt-link
          to="/"
          class="osk-header-mini__logo"
        >
          <img
            src="~assets/img/oskelly-logo-main.svg"
            alt="Oskelly logo"
          >
        </nuxt-link>

        <a
          :href="$t('data.footer.hotlineHref')"
          class="osk-header-mini__phone"
        >
          <span class="osk-header-mini__phone-text">
            <span>{{ $t('components.business.aboutService.aboutServiceContent.help.call') }}</span>
            <strong v-html="$t('data.footer.hotlineText')" />
          </span>

          <UikitIcon
            name="Phone"
            size="l"
            class="osk-header-mini__phone-icon"
          />
        </a>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-mini {
  &__content {
    padding: $indent-medium 0;

    @include media-query(lg-and-up) {
      padding: $indent-mlarge 0;
    }

    .osk-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 128px;

    @include media-query(lg-and-up) {
      width: auto;
    }
  }

  &__phone {
    display: flex;
  }

  &__phone-text {
    display: none;
    flex-direction: column;
    line-height: $line-height-subheading;
    text-align: right;

    @include media-query(lg-and-up) {
      display: flex;
    }
  }

  &__phone-icon {
    @include media-query(lg-and-up) {
      margin: auto 0 0 $indent-compact;
    }
  }
}
</style>
